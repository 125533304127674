<template>
  <div class="messageIndex-container">
    <router-view/>
  </div>
  
</template>

<script>
export default {
  name: 'messageIndex',
  components: {
  }
}

</script>

<style>
 
</style>
